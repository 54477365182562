.route-decode-base64 {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.route-decode-base64-title p {
  font-size: 16px;
  font-weight: bold;
}
.route-decode-base64-input{
  display: flex;
  justify-content: space-between;
}

.route-decode-base64-input textarea{
  margin: 0 8px;
}
.route-decode-base64-btn{
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.route-decode-base64-btn button:not(:first-child){
  margin-left: 8px;
}