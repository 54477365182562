.URLPrettier-header {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 38px;
}
.URLPrettier-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
}
.URLPrettier-actions {
  display: flex;
  padding: 12px 0;
}
.URLPrettier-actions button {
  margin-right: 12px;
}
.URLPrettier-content-error {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: red;
}

.URLPrettier-popover-content p {
  margin: 0;
  padding:0;
}