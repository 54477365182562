.JSONPrettier {
  position: absolute;
  top:64px;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  flex-direction:column;
  background-color: rgb(240 245 246);
}
.JSONPrettier-header {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 38px;
}
.JSONPrettier-content {
  flex: 1;
  display: flex;
  position:absolute;
  top:38px;
  left:8px;
  right:8px;
  bottom:8px;
  border-radius: 8px;
  box-shadow: 2px -2px 2px #efefef;
  background-color: #fff;
}
.JSONPrettier-content-item {
  display: flex;
  padding: 12px;
  overflow: hidden;
}
.JSONPrettier-content-item .react-json-view{
  flex: 1;
  overflow: auto;
}
.JSONPrettier-content-item > textarea{
  display: block;
  flex:1;
  resize: none;
}
.JSONPrettier-content-actions {
  display: flex;
  justify-content: center;
  padding: 12px;
}
.JSONPrettier-content-error {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: red;
}
.JSONPrettier-content-divider {
  display: flex;
  align-items: center;
  width: 2px;
  overflow: visible;
  background-color: #efefef;
}
.JSONPrettier-content-divider button {
  margin-left: -8px;
}
.JSONPrettier-content-divider button{
  display: inline-block;
  padding: 4px 2px;
  border-radius: 4px;
  /* background-color: #999; */
}
.JSONPrettier-popover-content p {
  margin: 0;
  padding:0;
}