.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.headerLogo h3{
  margin: 0;
}

.headerActions {
  display: flex;
}

.headerActions button {
  margin: 0 8px;
}