.EncodeUrl {
    display: flex;
    flex-direction:column;
    background-color: rgb(240 245 246);
  }
  .EncodeUrl-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 38px;
  }
  .EncodeUrl-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 2px -2px 2px #efefef;
    background-color: #fff;
  }
  .EncodeUrl-content-item {
    display: flex;
    flex-direction: column;
    padding: 12px;
    overflow: hidden;
  }
  .EncodeUrl-content-actions {
    display: flex;
    justify-content: center;
    padding: 12px;
  }
  .EncodeUrl-content-error {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: red;
  }
  .EncodeUrl-content-divider {
    display: flex;
    align-items: center;
    width: 2px;
    overflow: visible;
    background-color: #efefef;
  }
  .EncodeUrl-content-divider button {
    margin-left: -8px;
  }
  .EncodeUrl-content-divider button{
    display: inline-block;
    padding: 4px 2px;
    border-radius: 4px;
    /* background-color: #999; */
  }
  .EncodeUrl-popover-content p {
    margin: 0;
    padding:0;
  }