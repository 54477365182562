.svgIconButton {
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
}

.svgIconButton:hover{
  cursor: pointer;
}

.svgIconButton:not(.svgIconSelected) > svg:hover {
  stroke: #fff;
}