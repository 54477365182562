.pinyin{
  display: flex;
  padding: 20px 12px;
  background-color: #fff;
}

.pinyinContent{
  flex: 1;
  padding-right: 12px;
}

.pinyinItemTitle{
  margin: 0;
  margin-bottom: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #000;
}

.pinyinOptions{
  width: 300px;
  padding-left: 4px;
  border-left: 1px solid #eee
}

.pinyinResult {
  min-height: 32px;
  margin:0;
  padding: 8px;
  color: #000;
  border-radius: 8px;
  background-color: #efefef;
  /* border: 1px solid #999; */
}

.pinyinResultItem{
  margin: 0;
  font-size: 13px;
  line-height: 20px;
}